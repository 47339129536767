import Mixins from "../../Mixins.js";
import Moment from "moment";

export default {
  name: "AddLoanAccountRestructure",
  mixins: [Mixins],
  data() {
    return {
      identity: {
        loanAccountId: "",
      },
      identifier: {
        akadNumberOld: "",
        cifId: "",
      },
      property: {
        animation: {
          step1: {
            addLoanAccountRestructure: {
              isLoading: false,
            },
          },
        },
      },
      dataForm: {
        step1: {
          outstanding: 0,
          outstandingMargin: 0,
          outstandingPeriod: 0,
          loanAccountNumber: "",
          useOldAkadNumber: false,
          akadNumber: "",
          akadDate: "",
          startDate: "",
          endDate: "",
          timePeriod: 0,
          billDay: 0,
          isAutodebt: false,
          nominalLoan: 0,
          nominalLoanReschedule: 0,
          rateMargin: 0,
          rateMarginTotal: 0,
          nominalMargin: 0,
          nominalTotal: 0,
          rateFlat: 0,
          rateEffective: 0,
          nominalInstallment: 0,
          purposeLoan: "",
          isSmsNotification: false,
          isNeedCollateral: false,
          nominalCollateral: 0,
          collateralSharedPercent: 0,
          ujrahDropGroup: 0,
          ujrahDropGroupSub: 0,
          accountSection: 0,
          gracePeriode: 0,
          graceReason: "",
          feeAdmin: 0,
          feeMaterai: 0,
          feeNotaris: 0,
          feeInsuranceLife: 0,
          feeInsuranceLoss: 0,
          feeInsuranceVehicle: 0,
          blockedFund: 0,
          feeTotal: 0,
          collectorId: "",
          participateBank: 0,
          participateCif: 0,
          profitShareBank: 0,
          profitProjection: 0,
          isRelated: false,
          sp3Id: "",
          margin: false,
          loanId: "",
          savingAccountNumberDro: "",
          savingAccountNumberInstallment: "",
          loanCategoryId: "",
          loanCollateralId: "",
          loanGroupId: "",
          loanGroupParentId: "",
          accountOfficerIdHandle: "",
          accountOfficerIdPromo: "",
          loanAccountParentId: "",
          isLineFacility: false,
          cifNumber: 0,
          cifIdName: "",
          cifCategoryName: "",
          officeName: "",
          cifMobilePhoneNumber: 0,
          sp3Date: "",
          sp3Subject: "",
          loanAkadTypeAcronym: "",
          totalParticipate: 0,
          isProfitShareBankPercentage: false,
          profitShareBankProjection: 0,
          totalMargin: 0,
          totalSellingPrice: 0,
          nominalPurchase: 0,
          journalRecord: {
            productCode: "",
            transactionCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_DROPPING_LOAN,
            journalRecordDetails: [],
          },
          akadNumberNotaril: "",
        },
      },
      options: {
        step1: {
          produkPembiayaan: [],
          grupPendanaan: [],
          subLoanGroup: [],
          loanGroupCategory: [],
          collector: [],
          accountOfficer: [],
          loanAkadType: [],
          loanChartOfAccountByLoanId: [],
          loanCollateral: [],
          savingAccountByCifId: [],
          produkPembiayaanByAkad: [],
        },
      },
    };
  },
  methods: {
    async getLoanAccountRecheduleById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListLoanAccount();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl: "transaction-loan/findById/" + this.identity.loanAccountId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.step1.akadNumberNotaril =
              resp.data.data.akadNumberNotaril;
            this.identifier.akadNumberOld = resp.data.data.akadNumber;
            this.dataForm.step1.loanAkadTypeAcronym = resp.data.data.mloan
              ? resp.data.data.mloan.rloanAkadType
                ? resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym
                : ""
              : "";
            this.changeSelectTypeAkadLoanAccountRestructure();
            this.dataForm.step1.loanAccountNumber =
              resp.data.data.loanAccountNumber;
            this.dataForm.step1.akadDate = resp.data.data.akadDate;
            this.dataForm.step1.startDate = resp.data.data.startDate;
            this.dataForm.step1.timePeriod = resp.data.data.timePeriod;
            this.dataForm.step1.outstandingPeriod =
              resp.data.data.outstandingPeriod;
            this.dataForm.step1.endDate = resp.data.data.endDate;
            this.identifier.cifId = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifId
                : ""
              : "";
            this.dataForm.step1.participateBank =
              resp.data.data.participateBank;
            this.dataForm.step1.participateCif = resp.data.data.participateCif;
            this.dataForm.step1.billDay = resp.data.data.billDay;
            this.dataForm.step1.isAutodebt = resp.data.data.isAutodebt;
            this.dataForm.step1.nominalLoanReschedule =
              resp.data.data.nominalLoan;
            this.dataForm.step1.outstanding = resp.data.data.outstanding;
            this.dataForm.step1.outstandingMargin =
              resp.data.data.outstandingMargin;
            this.dataForm.step1.purposeLoan = resp.data.data.purposeLoan;
            this.dataForm.step1.isSmsNotification =
              resp.data.data.isSmsNotification;
            this.dataForm.step1.isNeedCollateral =
              resp.data.data.isNeedCollateral;
            this.dataForm.step1.nominalCollateral =
              resp.data.data.nominalCollateral;
            this.dataForm.step1.collateralSharedPercent =
              resp.data.data.collateralSharedPercent;
            this.dataForm.step1.ujrahDropGroup = resp.data.data.ujrahDropGroup;
            this.dataForm.step1.ujrahDropGroupSub =
              resp.data.data.ujrahDropGroupSub;
            this.dataForm.step1.accountSection = resp.data.data.accountSection;
            this.dataForm.step1.gracePeriode = resp.data.data.gracePeriode;
            this.dataForm.step1.graceReason = resp.data.data.graceReason;
            this.dataForm.step1.feeAdmin = resp.data.data.feeAdmin;
            this.dataForm.step1.feeMaterai = resp.data.data.feeMaterai;
            this.dataForm.step1.feeNotaris = resp.data.data.feeNotaris;
            this.dataForm.step1.feeInsuranceLife =
              resp.data.data.feeInsuranceLife;
            this.dataForm.step1.feeInsuranceLoss =
              resp.data.data.feeInsuranceLoss;
            this.dataForm.step1.feeInsuranceVehicle =
              resp.data.data.feeInsuranceVehicle;
            this.dataForm.step1.blockedFund = resp.data.data.blockedFund;
            this.dataForm.step1.feeTotal = resp.data.data.feeTotal;
            this.dataForm.step1.collectorId = resp.data.data.collectorId
              ? resp.data.data.collectorId.userId
              : "";
            this.dataForm.step1.profitShareBank =
              resp.data.data.profitShareBank;
            this.dataForm.step1.profitShareBankProjection =
              resp.data.data.profitShareBank;
            this.dataForm.step1.rateFlat = resp.data.data.rateFlat;
            this.dataForm.step1.rateEffective = resp.data.data.rateEffective;
            this.dataForm.step1.margin =
              resp.data.data.mloan.rloanMarginCalculate
                .loanMarginCalculateId === "1"
                ? true
                : false;
            this.dataForm.step1.nominalLoan = resp.data.data.nominalLoan;
            this.dataForm.step1.profitProjection =
              resp.data.data.profitProjection;
            this.dataForm.step1.nominalInstallment =
              resp.data.data.nominalInstallment;
            this.dataForm.step1.isRelated = resp.data.data.isRelated;
            this.dataForm.step1.loanId = resp.data.data.mloan
              ? resp.data.data.mloan.loanId
              : "";
            this.dataForm.step1.savingAccountNumberDro = resp.data.data
              .tsavingAccountDroping
              ? resp.data.data.tsavingAccountDroping.accountNumber
              : "";
            this.dataForm.step1.savingAccountNumberInstallment = resp.data.data
              .tsavingAccountInstallment
              ? resp.data.data.tsavingAccountInstallment.accountNumber
              : "";
            this.dataForm.step1.loanCategoryId = resp.data.data
              .rloanGroupCategory
              ? resp.data.data.rloanGroupCategory.loanGroupCategoryId
              : "";
            this.dataForm.step1.loanCollateralId = resp.data.data
              .mloanCollateral
              ? resp.data.data.mloanCollateral.loanCollateralId
              : "";
            this.dataForm.step1.loanGroupParentId = resp.data.data.mloanGroup
              ? resp.data.data.mloanGroup.loanGroupParentId
                ? resp.data.data.mloanGroup.loanGroupParentId.loanGroupId
                : ""
              : "";
            this.dataForm.step1.loanGroupId = resp.data.data.mloanGroup
              ? resp.data.data.mloanGroup.loanGroupId
              : "";
            this.changeLoanGroupLoanAccount(this.dataForm.step1.loanGroupId);
            this.dataForm.step1.accountOfficerIdHandle = resp.data.data
              .maccountOfficerHandle
              ? resp.data.data.maccountOfficerHandle.userId
              : "";
            this.dataForm.step1.accountOfficerIdPromo = resp.data.data
              .maccountOfficerPromo
              ? resp.data.data.maccountOfficerPromo.userId
              : "";
            this.dataForm.step1.isLineFacility = resp.data.data.isLineFacility;
            this.dataForm.step1.cifNumber = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifNumber
                : ""
              : "";
            this.dataForm.step1.cifIdName = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifIdName
                : ""
              : "";
            this.dataForm.step1.cifCategoryName = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.rcifCategoryByCifCategoryId
                  ? resp.data.data.tloanSp3.mcif.rcifCategoryByCifCategoryId
                      .cifCategoryName
                  : ""
                : ""
              : "";
            this.dataForm.step1.officeName = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.moffice
                  ? resp.data.data.tloanSp3.mcif.moffice.officeName
                  : ""
                : ""
              : "";
            this.dataForm.step1.cifMobilePhoneNumber = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifMobilePhoneNumber
                : ""
              : "";
            this.dataForm.step1.sp3Date = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.sp3Date
              : "";
            this.dataForm.step1.sp3Subject = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.sp3Subject
              : "";
            this.dataForm.step1.cifMobilePhoneNumber = resp.data.data.tloanSp3
              ? resp.data.data.tloanSp3.mcif
                ? resp.data.data.tloanSp3.mcif.cifMobilePhoneNumber
                : ""
              : "";
            this.dataForm.step1.nominalPurchase =
              resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
              resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
              resp.data.data.mloan.rloanAkadType.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
                ? resp.data.data.nominalPurchase
                : 0;
            if (
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
            ) {
              this.dataForm.step1.rateMargin = resp.data.data.rateFlat;
              this.calculateRateLoanAccountRestructure("get_by_id");
            }
            if (
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
              this.dataForm.step1.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
            ) {
              this.dataForm.step1.totalParticipate =
                this.dataForm.step1.participateBank +
                this.dataForm.step1.participateCif;
            }
            this.getRefferenceLoanChartOfAccountByLoanIdLoanAccountRestructure(
              resp.data.data.journalRecord
            );
            this.getSavingAccountByCifId();
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageListLoanAccount(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : `Terjadi Kesalahan`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListLoanAccount(),
          });
        }
      }
    },
    routeToPageListLoanAccount() {
      this.$router.push("/loan/loan-account-restructure");
    },
    resetFormNominalPembiayaanLoanAccountRestructure() {
      this.dataForm.step1.participateBank = 0;
      this.dataForm.step1.participateCif = 0;
      this.dataForm.step1.rateFlat = 0;
      this.dataForm.step1.rateEffective = 0;
      this.dataForm.step1.profitShareBank = 0;
      this.dataForm.step1.nominalPurchase = 0;
      this.dataForm.step1.nominalLoan = 0;
      this.dataForm.step1.nominalInstallment = 0;
      this.dataForm.step1.rateMargin = 0;
      this.dataForm.step1.totalMargin = 0;
      this.dataForm.step1.totalSellingPrice = 0;
      this.dataForm.step1.nominalMargin = 0;
      this.dataForm.step1.totalParticipate = 0;
      this.dataForm.step1.profitProjection = 0;
      this.dataForm.step1.profitShareBankProjection = 0;
      this.dataForm.step1.isProfitShareBankPercentage = false;
    },
    calculateRateLoanAccountRestructure(triggerby) {
      this.dataForm.step1.rateFlat = this.dataForm.step1.rateMargin;
      if (triggerby === "manual_input") {
        if (
          this.dataForm.step1.margin === false &&
          this.dataForm.step1.nominalPurchase &&
          this.dataForm.step1.rateMargin
        ) {
          const payload = {
            rateFlat: this.dataForm.step1.rateMargin,
            principal: this.dataForm.step1.nominalPurchase,
          };
          this.convertToEffectiveLoanAccountRestructure(
            payload.rateFlat,
            payload.principal
          );
        }
      }
      this.dataForm.step1.totalMargin = this.dataForm.step1.timePeriod
        ? (this.dataForm.step1.rateMargin / 12) * this.dataForm.step1.timePeriod
        : 0;
      this.dataForm.step1.nominalMargin = this.dataForm.step1.totalMargin
        ? (this.dataForm.step1.totalMargin *
            this.dataForm.step1.nominalPurchase) /
          100
        : 0;
      this.dataForm.step1.totalSellingPrice = this.dataForm.step1.nominalMargin
        ? this.dataForm.step1.nominalMargin +
          this.dataForm.step1.nominalPurchase
        : 0;
      this.dataForm.step1.nominalLoan = this.dataForm.step1.totalSellingPrice;
      this.dataForm.step1.nominalInstallment =
        this.dataForm.step1.nominalLoan / this.dataForm.step1.timePeriod;
    },
    async convertToEffectiveLoanAccountRestructure(rateFlat, principal) {
      const payload = {
        timePeriod: this.dataForm.step1.timePeriod,
        rateFlat: rateFlat,
        principal: principal,
      };
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/convert-flat-to-efective",
          payload: payload,
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.step1.rateEffective = resp.data.data;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async getRefferenceLoanChartOfAccountByLoanIdLoanAccountRestructure(
      currentJournalRecord
    ) {
      try {
        this.dataForm.step1.journalRecord.journalRecordDetails = [];
        const resp = await this.$store.dispatch({
          endPoint: "loan",
          type: "GET_DATA_FIND_BY",
          reqUrl: `loan-chart-of-account/loan-transaction/${
            this.dataForm.step1.loanId
          }/${
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_DROPPING_LOAN_ID
          }`,
        });
        if (resp.data.code === "SUCCESS") {
          console.log(resp);
          Promise.all(
            resp.data.data.map((i, index) => {
              console.log(index, i);
              const productLedgerId = i.loanChartOfAccountId;
              const chartOfAccountId = i.chartOfAccount
                ? i.chartOfAccount.chartOfAccountId
                : "";
              const label = i.productLedger
                ? i.productLedger.productLedgerName
                : "";
              const positionMutation = i.mutationPosition;
              const labelInputComponent = i.chartOfAccount
                ? i.chartOfAccount.description
                : "";
              const pairPositionMutation = i.pairMutationPosition;
              const pairChartOfAccountId = i.pairChartOfAccount
                ? i.pairChartOfAccount.chartOfAccountId
                : "";
              const chartOfAccountCode = i.chartOfAccount
                ? i.chartOfAccount.chartOfAccountCode
                : "";
              const pairChartOfAccountCode = i.pairChartOfAccount
                ? i.pairChartOfAccount.pairChartOfAccountCode
                : "";
              const pairType = i.pairType;
              const amount = 0;
              this.dataForm.step1.journalRecord.journalRecordDetails.push({
                productLedgerId,
                chartOfAccountId,
                pairChartOfAccountId,
                labelInputComponent,
                label,
                positionMutation,
                pairPositionMutation,
                chartOfAccountCode,
                pairChartOfAccountCode,
                pairType,
                amount,
                index: index,
              });
            })
          ).then(() => {
            if (currentJournalRecord) {
              this.insertJournalRecordLoanAccountRestructure(
                currentJournalRecord
              );
            }
          });
        }
      } catch (error) {}
    },
    async getSavingAccountByCifId() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListLoanAccount();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "saving-account/cif-id/" + this.identifier.cifId,
          payload: {
            isCrossOffice: true,
          },
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.mcif.cifIdName} - ${index.accountNumber} - ${index.msaving.savingName}`;
            const value = index.accountNumber;
            this.options.step1.savingAccountByCifId.push({ text, value });
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buef.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : `Terjadi Kesalahan !`,
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    insertJournalRecordLoanAccountRestructure(currentJournalRecord) {
      this.dataForm.step1.journalRecord.journalRecordDetails.map((index) => {
        currentJournalRecord.journalRecordDetails.map((i) => {
          if (i.chartOfAccountId === index.chartOfAccountId) {
            index.amount = i.amount;
          }
        });
      });
    },
    changeSelectTypeAkadLoanAccountRestructure() {
      this.resetFormNominalPembiayaanLoanAccountRestructure();
      const filterAkad = this.options.step1.produkPembiayaan.filter(
        (index) =>
          index.loanAkadTypeAcronym === this.dataForm.step1.loanAkadTypeAcronym
      );
      this.dataForm.step1.loanId = "";
      this.options.step1.produkPembiayaanByAkad = [
        { value: "", text: "-- Pilih --" },
        ...filterAkad,
      ];
    },
    changeTipeBagiHasilBankLoanAccountRestructure() {
      switch (this.dataForm.step1.isProfitShareBankPercentage) {
        case true:
          if (
            this.dataForm.step1.profitShareBank !== 0 &&
            this.dataForm.step1.profitProjection !== 0
          ) {
            this.dataForm.step1.profitShareBank =
              (this.dataForm.step1.profitShareBank /
                this.dataForm.step1.profitProjection) *
              100;
            this.dataForm.step1.profitShareBankProjection =
              (this.dataForm.step1.profitProjection *
                this.dataForm.step1.profitShareBank) /
              100;
            return;
          }
          this.dataForm.step1.profitShareBank = 0;
          this.dataForm.step1.profitShareBankProjection = 0;
          break;

        case false:
          if (
            this.dataForm.step1.profitShareBank !== 0 &&
            this.dataForm.step1.profitProjection !== 0
          ) {
            this.dataForm.step1.profitShareBankProjection =
              (this.dataForm.step1.profitProjection *
                this.dataForm.step1.profitShareBank) /
              100;
            this.dataForm.step1.profitShareBank =
              (this.dataForm.step1.profitProjection *
                this.dataForm.step1.profitShareBank) /
              100;
            return;
          }
          this.dataForm.step1.profitShareBank = 0;
          this.dataForm.step1.profitShareBankProjection = 0;
          break;

        default:
          break;
      }
    },
    changeProfitShareBankPercentLoanAccountRestructure() {
      switch (this.dataForm.step1.isProfitShareBankPercentage) {
        case true:
          this.dataForm.step1.profitShareBankProjection =
            (this.dataForm.step1.profitProjection *
              this.dataForm.step1.profitShareBank) /
            100;

          break;
        case false:
          this.dataForm.step1.profitShareBankProjection = this.dataForm.step1.profitShareBank;
          break;

        default:
          break;
      }
      this.calculateRateEffectiveAnnuallyLoanAccountRestructure();
      this.dataForm.step1.nominalLoan = this.dataForm.step1.participateBank;
      this.calculateProyeksiAngsuranLoanAccountRestructure();
    },
    calculateRateEffectiveAnnuallyLoanAccountRestructure() {
      if (
        this.dataForm.step1.profitShareBankProjection === 0 ||
        this.dataForm.step1.participateBank === 0 ||
        this.dataForm.step1.timePeriod === 0
      ) {
        this.dataForm.step1.rateEffective = 0;
        this.dataForm.step1.rateFlat = 0;
        return;
      }
      const calculateRate =
        (this.dataForm.step1.profitShareBankProjection /
          this.dataForm.step1.participateBank) *
        100;
      if (this.dataForm.step1.margin === false) {
        const payload = {
          rateFlat: (calculateRate / this.dataForm.step1.timePeriod) * 12,
          principal: this.dataForm.step1.participateBank,
        };
        this.convertToEffectiveLoanAccountRestructure(
          payload.rateFlat,
          payload.principal
        );
      }
      this.dataForm.step1.rateFlat =
        (calculateRate / this.dataForm.step1.timePeriod) * 12;
    },
    calculateProyeksiAngsuranLoanAccountRestructure() {
      if (
        this.dataForm.step1.nominalLoan !== 0 &&
        this.dataForm.step1.timePeriod !== 0
      ) {
        this.dataForm.step1.nominalInstallment =
          this.dataForm.step1.nominalLoan / this.dataForm.step1.timePeriod;
        return;
      }
      this.dataForm.step1.nominalInstallment = 0;
    },
    changeProfitProjectionLoanAccountRestructure() {
      if (this.dataForm.step1.isProfitShareBankPercentage === true) {
        this.changeProfitShareBankPercentLoanAccountRestructure();
        this.dataForm.step1.nominalLoan = this.dataForm.step1.participateBank;
        this.calculateProyeksiAngsuranLoanAccountRestructure();
      }
      this.calculateRateEffectiveAnnuallyLoanAccountRestructure();
    },
    calculateParticipateLoanAccountRestructure(type) {
      this.dataForm.step1.totalParticipate =
        this.dataForm.step1.participateBank +
        this.dataForm.step1.participateCif;
      this.dataForm.step1.nominalLoan = this.dataForm.step1.participateBank;

      if (type === "WITH_CALCULATE_RATE") {
        this.calculateRateEffectiveAnnuallyLoanAccountRestructure();
        this.calculateProyeksiAngsuranLoanAccountRestructure();
      }
    },
    changeNominalMarginLoanAccountRestructure() {
      if (
        this.dataForm.step1.nominalMargin &&
        this.dataForm.step1.nominalPurchase &&
        this.dataForm.step1.timePeriod
      ) {
        this.dataForm.step1.totalMargin =
          (this.dataForm.step1.nominalMargin /
            this.dataForm.step1.nominalPurchase) *
          100;
        this.dataForm.step1.rateMargin =
          (this.dataForm.step1.totalMargin / this.dataForm.step1.timePeriod) *
          12;
      }
      this.calculateRateLoanAccountRestructure("manual_input");
    },
    async addLoanAccountRestructure() {
      const payload = {
        akadNumber: this.dataForm.step1.akadNumber,
        akadDate: this.dataForm.step1.akadDate,
        startDate: this.dataForm.step1.startDate,
        timePeriod: this.dataForm.step1.timePeriod,
        billDay: this.dataForm.step1.billDay,
        isAutodebt: this.dataForm.step1.isAutodebt,
        nominalLoan: this.dataForm.step1.nominalLoan,
        rateFlat: this.dataForm.step1.rateFlat,
        rateEffective: this.dataForm.step1.rateEffective,
        nominalInstallment: this.dataForm.step1.nominalInstallment,
        purposeLoan: this.dataForm.step1.purposeLoan,
        isSmsNotification: this.dataForm.step1.isSmsNotification,
        isNeedCollateral: this.dataForm.step1.isNeedCollateral,
        nominalCollateral: this.dataForm.step1.nominalCollateral,
        collateralSharedPercent: this.dataForm.step1.collateralSharedPercent,
        ujrahDropGroup: this.dataForm.step1.ujrahDropGroup,
        ujrahDropGroupSub: this.dataForm.step1.ujrahDropGroupSub,
        accountSection: this.dataForm.step1.accountSection,
        feeAdmin: this.dataForm.step1.feeAdmin,
        feeMaterai: this.dataForm.step1.feeMaterai,
        feeNotaris: this.dataForm.step1.feeNotaris,
        feeInsuranceLife: this.dataForm.step1.feeInsuranceLife,
        feeInsuranceLoss: this.dataForm.step1.feeInsuranceLoss,
        feeInsuranceVehicle: this.dataForm.step1.feeInsuranceVehicle,
        blockedFund: this.dataForm.step1.blockedFund,
        feeTotal: this.dataForm.step1.feeTotal,
        collectorId: this.dataForm.step1.collectorId,
        participateBank: this.dataForm.step1.participateBank,
        participateCif: this.dataForm.step1.participateCif,
        profitShareBank: this.dataForm.step1.profitShareBank,
        profitProjection: this.dataForm.step1.profitProjection,
        isRelated: this.dataForm.step1.isRelated,
        isLineFacility: this.dataForm.step1.isLineFacility,
        sp3Id: this.dataForm.step1.sp3Id,
        loanId: this.dataForm.step1.loanId,
        savingAccountNumberDro: this.dataForm.step1.savingAccountNumberDro,
        savingAccountNumberInstallment: this.dataForm.step1
          .savingAccountNumberInstallment,
        loanAccountParentId: this.identity.loanAccountId,
        loanCategoryId: this.dataForm.step1.loanCategoryId,
        loanCollateralId: this.dataForm.step1.loanCollateralId,
        loanGroupId: this.dataForm.step1.loanGroupId,
        accountOfficerIdHandle: this.dataForm.step1.accountOfficerIdHandle,
        accountOfficerIdPromo: this.dataForm.step1.accountOfficerIdPromo,
        nominalPurchase: this.dataForm.step1.nominalPurchase,
        journalRecord: this.dataForm.step1.journalRecord,
        isProfitShareBankPercentage: this.dataForm.step1
          .isProfitShareBankPercentage,
        akadNumberNotaril: this.dataForm.step1.akadNumberNotaril,
      };
      console.log(JSON.stringify(payload));
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step1.addLoanAccountRestructure.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl: "transaction-loan/restructure",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.$router.push(
                      `/loan/loan-account-restructure/${this.encryptBASE64(
                        `${resp.data.data.loanAccountId}`
                      )}`
                    );
                  });
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step1.addLoanAccountRestructure.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    changeUseAkadNumberOld(event) {
      if (event === false) {
        this.dataForm.step1.akadNumber = null;
      } else {
        this.dataForm.step1.akadNumber = this.identifier.akadNumberOld;
      }
    },
    async getSystemDate() {
      this.dataForm.step1.akadDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.dataForm.step1.startDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.dataForm.step1.endDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    async changeLoanGroupLoanAccount(loanGroupId) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          reqUrl: "m-loan-group/sub",
          endPoint: "loan",
          payload: {
            groupId: this.dataForm.step1.loanGroupParentId,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.step1.subLoanGroup = [];
          this.dataForm.step1.loanGroupId = loanGroupId
            ? this.dataForm.step1.loanGroupId
            : "";
          resp.data.data.map((data) => {
            const text = `${data.groupCode} - ${data.groupName}`;
            const value = data.loanGroupId;
            this.options.step1.subLoanGroup.push({ text, value });
          });
        }
      } catch (error) {}
    },
    changeSelectProdukPembiayaanLoanAccountRestructure() {
      this.getLoanChartOfAccountByLoanIdLoanAccountRestructure();
      this.options.step1.produkPembiayaan.map((i) => {
        if (i.value === this.dataForm.step1.loanId) {
          this.dataForm.step1.journalRecord.productCode = i.loanCode;
          console.log(i);
          switch (i.marginCalculate) {
            case "1":
              this.dataForm.step1.margin = true;
              this.dataForm.step1.rateEffective = 0;
              break;
            case "2":
              this.dataForm.step1.margin = false;
              this.dataForm.step1.rateFlat = 0;
              break;

            default:
              return;
              break;
          }
        }
      });
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
      ) {
        this.calculateRateLoanAccountRestructure("manual_input");
      }
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
      ) {
        this.calculateRateEffectiveAnnuallyLoanAccountRestructure();
        this.calculateProyeksiAngsuranLoanAccountRestructure();
      }
    },
    async getLoanChartOfAccountByLoanIdLoanAccountRestructure() {
      try {
        this.dataForm.step1.journalRecord.journalRecordDetails = [];
        const resp = await this.$store.dispatch({
          endPoint: "loan",
          type: "GET_DATA_FIND_BY",
          reqUrl: `loan-chart-of-account/loan-transaction/${
            this.dataForm.step1.loanId
          }/${
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_DROPPING_LOAN_ID
          }`,
        });
        if (resp.data.code === "SUCCESS") {
          console.log(resp);
          resp.data.data.map((i, index) => {
            console.log(index, i);
            const productLedgerId = i.loanChartOfAccountId;
            const chartOfAccountId = i.chartOfAccount
              ? i.chartOfAccount.chartOfAccountId
              : "";
            const label = i.productLedger
              ? i.productLedger.productLedgerName
              : "";
            const positionMutation = i.mutationPosition;
            const labelInputComponent = i.chartOfAccount
              ? i.chartOfAccount.description
              : "";
            const pairPositionMutation = i.pairMutationPosition;
            const pairChartOfAccountId = i.pairChartOfAccount
              ? i.pairChartOfAccount.chartOfAccountId
              : "";
            const chartOfAccountCode = i.chartOfAccount
              ? i.chartOfAccount.chartOfAccountCode
              : "";
            const pairChartOfAccountCode = i.pairChartOfAccount
              ? i.pairChartOfAccount.pairChartOfAccountCode
              : "";
            const pairType = i.pairType;
            const amount = 0;
            this.dataForm.step1.journalRecord.journalRecordDetails.push({
              productLedgerId,
              chartOfAccountId,
              pairChartOfAccountId,
              label,
              positionMutation,
              labelInputComponent,
              pairPositionMutation,
              chartOfAccountCode,
              pairChartOfAccountCode,
              pairType,
              amount,
              index: index,
            });
          });
        }
      } catch (error) {}
    },
    changeStartDateLoanAccountRestructure() {
      if (this.dataForm.step1.timePeriod) {
        this.changeTimePeriodLoanAccountRestructure();
      }
    },
    changeTimePeriodLoanAccountRestructure() {
      if (this.dataForm.step1.startDate !== "") {
        var periode =
          this.dataForm.step1.timePeriod === 0 ||
          this.dataForm.step1.timePeriod === ""
            ? 1
            : this.dataForm.step1.timePeriod;
        var calculate = Moment()
          .add(`${periode}`, "months")
          .format();
        this.dataForm.step1.endDate = calculate;
      }
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
      ) {
        this.calculateRateLoanAccountRestructure("manual_input");
      }
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
      ) {
        this.calculateRateEffectiveAnnuallyLoanAccountRestructure();
        this.calculateProyeksiAngsuranLoanAccountRestructure();
      }
    },
    changeEndDateLoanAccountRestructure() {
      if (
        this.dataForm.step1.endDate !== "" &&
        this.dataForm.step1.startDate !== ""
      ) {
        var a = Moment(this.dataForm.step1.endDate);
        var b = Moment(this.dataForm.step1.startDate);
        this.dataForm.step1.timePeriod = a.diff(b, "months");
        if (
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
        ) {
          this.calculateRateLoanAccountRestructure("manual_input");
        }
        if (
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
        ) {
          this.calculateRateEffectiveAnnuallyLoanAccountRestructure();
          this.calculateProyeksiAngsuranLoanAccountRestructure();
        }
      }
    },
    calculateFeeLoanAccountRestructure() {
      this.dataForm.step1.feeTotal =
        (!this.dataForm.step1.feeAdmin ? 0 : this.dataForm.step1.feeAdmin) +
        (!this.dataForm.step1.feeMaterai ? 0 : this.dataForm.step1.feeMaterai) +
        (!this.dataForm.step1.feeNotaris ? 0 : this.dataForm.step1.feeNotaris) +
        (!this.dataForm.step1.feeInsuranceLife
          ? 0
          : this.dataForm.step1.feeInsuranceLife) +
        (!this.dataForm.step1.feeInsuranceLoss
          ? 0
          : this.dataForm.step1.feeInsuranceLoss) +
        (!this.dataForm.step1.feeInsuranceVehicle
          ? 0
          : this.dataForm.step1.feeInsuranceVehicle);
    },
    getReferenceCollector() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_USER_MANAGEMENT", {
          url: "v2/user/active-user",
          params: {
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((index) => {
            const text = `${index.profileSurename} - ${index.userNik}`;
            const value = index.userId;
            this.options.step1.collector.push({ text, value });
          });
        });
    },
    getReferenceLoan() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
          url: "loan/simple-list",
          params: {
            loanName: "",
            loanCode: "",
            page: 0,
          },
        })
        .then((resp) => {
          Promise.all(
            resp.data.data.content.map((index) => {
              const text = `${index.loanName} - ${index.loanCode}`;
              const value = index.loanId;
              const marginCalculate = index.loanMarginCalculateCode;
              const loanAkadTypeAcronym = index.loanAkadTypeAcronym;
              const loanCode = index.loanCode;
              this.options.step1.produkPembiayaan.push({
                text,
                value,
                marginCalculate,
                loanAkadTypeAcronym,
                loanCode,
              });
            })
          ).then(() => {
            this.getLoanAccountRecheduleById();
          });
        });
    },
    async getReferenceLoanAkadType() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "reference/loan-akad-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanAkadTypeName;
            const value = index.loanAkadTypeAcronym;
            this.options.step1.loanAkadType.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error.response);
      }
    },
    getReferenceLoanGroupCategory() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-group-category",
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.map((i) => {
              const text = i.loanGroupCategoryName;
              const value = i.loanGroupCategoryId;
              this.options.step1.loanGroupCategory.push({ text, value });
            });
            // eslint-disable-next-line no-empty
          } else {
          }
        });
    },
    getReferenceLoanGroup() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
          url: "m-loan-group",
          params: {
            groupCode: "",
            groupName: "",
            accountNumber: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((i) => {
              const text = `${i.groupCode} - ${i.groupName}`;
              const value = i.loanGroupId;
              this.options.step1.grupPendanaan.push({ text, value });
            });
          }
        });
    },
    getReferenceAccountOfficer() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_USER_MANAGEMENT", {
          url: "v2/user/active-user",
          params: {
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((index) => {
            const text = `${index.profileSurename} - ${index.userNik}`;
            const value = index.userId;
            this.options.step1.accountOfficer.push({ text, value });
          });
        });
    },
    getRefferenceLoanCollateral() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
          url: "loan-collateral",
          params: {
            collateralTypeId: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            console.log(resp);
            resp.data.data.content.map((index) => {
              const text = index.description;
              const value = index.loanCollateralId;
              this.options.step1.loanCollateral.push({
                text,
                value,
              });
            });
          }
        });
    },
    changeIsNeedCollateralLoanAccountRestructure(event) {
      if (event === false) {
        this.dataForm.step1.nominalCollateral = 0;
      }
    },
    checkinMainId() {
      var query = this.$route.query.refId;
      if (!query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListLoanAccount();
        return;
      }
      var decrypt = JSON.parse(this.decryptBASE64(query));
      this.identity.loanAccountId = decrypt.loanAccountId;
      this.dataForm.step1.sp3Id = decrypt.loanSp3Id;
    },
  },
  mounted() {
    this.getSystemDate();
    this.checkinMainId();
    this.getReferenceCollector();
    this.getReferenceLoanAkadType();
    this.getReferenceLoan();
    this.getReferenceAccountOfficer();
    this.getReferenceLoanGroup();
    this.getReferenceLoanGroupCategory();
    this.getRefferenceLoanCollateral();
  },
};
